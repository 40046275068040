@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  background-color: #ebf0f1;
}

#qrScanner__scan_region {
  display: flex;
  justify-content: center;
}
#qrScanner > div:first-child {
  display: none;
}
#html5-qrcode-select-camera {
  width: 95%;
}

#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl.mapboxgl-compact {
  display: none !important;
}
input:focus {
  outline: none;
}

#hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
